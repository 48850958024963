import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useLogs } from "../../providers/LogProvider";
import { AutoComplete, Button, Flex, Input, Modal, Typography } from "antd";
import dayjs from "dayjs";
import { DIRECTIONS } from "../../api/types";
import utc from "dayjs/plugin/utc";
import { TimePickers } from "../../components/TimePickers";
import { ActiveFilters } from "../../components/ActiveFilters";
import { LiveTail } from "../../components/LiveTail";
import { ClearAllFilters } from "../../components/ClearAllFilters";
import { Table } from "../../components/LogTable/Table";
import { WorkerFilter } from "../../components/WorkerFilter";
import { LogSettings } from "../../components/LogSettings";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Search } = Input;

dayjs.extend(utc);

export const Logs = () => {
  const {
    getLogs,
    logs,
    activeApp,
    apps,
    searchPhrase,
    filters,
    setFilters,
    liveTail,
    resetLogs,
    highlightedLog,
    setHighlightedLog,
    responseTime,
    requestTime,
    setIsLoading,
    getHistory,
    history,
  } = useLogs();
  const [showHint, setShowHint] = useState<boolean>(false);
  const [sp, setSearchParams] = useSearchParams();
  const [tempSearch, setTempSearch] = useState<string>("");
  useEffect(() => {
    if (logs.length === 0) {
      setIsLoading(true);
    }
  }, []);

  useEffect(() => {
    getHistory();
  }, [activeApp]);

  useEffect(() => {
    if (searchPhrase?.length === 0 || searchPhrase === null) {
      setTempSearch("");
    }
  }, [searchPhrase]);

  useEffect(() => {
    if (
      filters &&
      filters.timestamp === "yes" &&
      highlightedLog &&
      logs.length > 0 &&
      responseTime > requestTime
    ) {
      document.querySelector(`#${highlightedLog}`)?.scrollIntoView();
    }
  }, [logs, highlightedLog, filters]);

  useEffect(() => {
    if (filters === null) {
      const currFilters: any = {};
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.size === 0) {
        const now = new Date();
        currFilters.dateFrom = now.getTime().toString();
        currFilters.dateTo = now.getTime().toString();
        currFilters.direction = DIRECTIONS.before;
        if (!currFilters.activeApp) {
          if (localStorage.getItem("appId")) {
            const app = apps.find(
              (app) => app.name === localStorage.getItem("appId"),
            );
            if (app) {
              currFilters.activeApp = app.id;
            }
          } else {
            currFilters.activeApp = apps[0].id;
          }
        }
        setFilters(currFilters);
        return;
      }
      for (const [key, value] of searchParams.entries()) {
        if (key === "worker") {
          //check if currFilters has key worker, if not assign empty array and push value, otherwise push value to existing array
          if (currFilters[key]) {
            currFilters[key].push(value);
          } else {
            currFilters[key] = [value];
          }
          continue;
        }
        if (key === "text") {
          currFilters.direction = DIRECTIONS.middle;
          setTempSearch(value);
        }
        // eslint-disable-next-line
        // @ts-ignore
        currFilters[key] = value;
      }

      setFilters(currFilters);
    }
  }, [filters]);

  useEffect(() => {
    const token = localStorage.getItem("token") || null;
    if (!token || filters === null) {
      return;
    }

    if (filters.timestamp !== "yes" && highlightedLog) {
      setHighlightedLog(null);
    }
    setSearchParams(filters);
    const dateFrom = filters.dateFrom
      ? new Date(parseInt(filters.dateFrom))
      : new Date();

    const dateTo = filters.dateTo
      ? new Date(parseInt(filters.dateTo))
      : new Date();
    const direction = filters.direction || DIRECTIONS.middle;
    getLogs({
      appId: filters.activeApp,
      text: filters.text || null,
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      direction,
      worker: filters.worker || [],
    });
    if (tempSearch.length === 0 && filters.text) {
      setTempSearch(filters.text);
    }
    if (tempSearch.length > 0 && !filters.text) {
      setTempSearch("");
    }
  }, [filters]);

  const handleSearchPhraseChange = (phrase: string) => {
    setTempSearch(phrase);
    if (!filters) {
      return;
    }
    const activeFilters = { ...filters };
    if (activeFilters.dateFrom === activeFilters.dateTo && phrase.length > 0) {
      activeFilters.dateFrom = dayjs().subtract(2, "h").valueOf().toString();
      activeFilters.dateTo = dayjs().valueOf().toString();
      activeFilters.relativeTime = "2#h";
      activeFilters.direction = DIRECTIONS.middle;
    }

    if (activeFilters.relativeTime && phrase.length > 0) {
      const now = dayjs();
      const relativeUnits = activeFilters.relativeTime.split("#");
      activeFilters.dateFrom = now
        .subtract(
          parseInt(relativeUnits[0]),
          relativeUnits[1] as dayjs.ManipulateType | undefined,
        )
        .valueOf()
        .toString();
      activeFilters.dateTo = now.valueOf().toString();
    }
    if (liveTail) {
      resetLogs();
    }
    if (filters.timestamp === "yes") {
      delete activeFilters["timestamp"];
    }
    setFilters({ ...activeFilters, text: phrase });
  };

  const showPrevLogs = () => {
    if (!filters) {
      return;
    }
    setFilters({
      ...filters,
      direction: DIRECTIONS.before,
      dateFrom: new Date(logs[0].date).getTime().toString(),
      dateTo: new Date(logs[0].date).getTime().toString(),
    });
  };

  const showNextLogs = () => {
    if (!filters) {
      return;
    }
    console.log(
      new Date(logs[logs.length - 1].date).getTime().toString(),
      "to",
    );
    setFilters({
      ...filters,
      direction: DIRECTIONS.after,
      dateFrom: new Date(logs[logs.length - 1].date).getTime().toString(),
      dateTo: new Date(logs[logs.length - 1].date).getTime().toString(),
    });
  };

  return (
    <>
      <Flex justify="space-between" align="center" style={{ margin: "20px 0" }}>
        <Flex
          align="center"
          style={{ position: "relative", width: "calc(100% - 310px)" }}
        >
          <Button
            onClick={() => setShowHint(true)}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <InfoCircleOutlined />
          </Button>
          <AutoComplete
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
            }
            notFoundContent={null}
            options={(history || []).map((d) => {
              return {
                value: d.text,
                label: (
                  <Flex justify="space-between">
                    <div>{d.text}</div>
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "12px" }}
                    >
                      {dayjs(d.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                    </Typography.Text>
                  </Flex>
                ),
              };
            })}
            onSelect={(val) => [
              setTempSearch(val),
              handleSearchPhraseChange(val),
            ]}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearchPhraseChange(tempSearch);
              }
            }}
            onChange={(e) => setTempSearch(e)}
            value={tempSearch}
          >
            <Search
              placeholder="Filter logs - press enter to search"
              allowClear
              onSearch={(value) => handleSearchPhraseChange(value)}
            />
          </AutoComplete>
        </Flex>
        <TimePickers key={activeApp} />
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        style={{ marginBottom: "10px" }}
      >
        <LiveTail />
        <WorkerFilter />
        <LogSettings />
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: "10px" }}
      >
        <ActiveFilters />
        <ClearAllFilters />
      </Flex>

      <Table logs={logs} />
      {showHint && (
        <Modal
          title="Search tips"
          open
          onCancel={() => setShowHint(false)}
          footer={null}
        >
          <ul style={{ listStyleType: "disc" }}>
            <li style={{ margin: "10px 0 0 15px" }}>
              Use <strong>AND</strong> to find results that contain both
              phrases. For example:
              <br />
              <strong>"apple juice" AND "orange"</strong> <br />
              This will match lines that contain both "apple juice" and
              "orange," with any text in between.
            </li>
            <li style={{ margin: "10px 0 0 15px" }}>
              Use <strong>OR</strong> to find results that contain either
              phrase. For example: <br />
              <strong>"apple juice" OR "orange"</strong> <br />
              This will match lines that contain either "apple juice" (exactly
              as a phrase) or the keyword "orange."
            </li>
            <li style={{ margin: "10px 0 0 15px" }}>
              Enclose phrases in <strong>double quotes</strong> to search for
              exact matches. For example: <br />
              <strong>"apple juice"</strong> will only match lines where the
              words appear next to each other.
            </li>
            <li style={{ margin: "10px 0 0 15px" }}>
              To exclude a phrase from your search results, use a{" "}
              <strong>- (minus sign)</strong> in front of the phrase. <br />
              For example, searching for <strong>"apple" -"banana"</strong> will
              return results that contain "apple" but do not contain "banana".
            </li>
          </ul>
        </Modal>
      )}
    </>
  );
};
