import { Button, Flex, Modal, Switch } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLogs } from "../../providers/LogProvider";

export const LogSettings = () => {
  const { settings, setSettings } = useLogs();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(settings));
  }, [settings]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleLogOrder = (checked: boolean) => {
    setSettings({ ...settings, orderAsc: checked });
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(!isModalOpen)}>
        <Flex justify="center" align="center">
          <SettingOutlined />
        </Flex>
      </Button>
      {isModalOpen && (
        <Modal
          footer={null}
          title="Settings"
          open
          onCancel={() => setIsModalOpen(false)}
          onOk={handleOk}
        >
          Log order
          <Switch
            style={{ marginLeft: "10px" }}
            onChange={(checked) => handleLogOrder(checked)}
            checkedChildren="ASC"
            unCheckedChildren="DESC"
            defaultChecked={settings.orderAsc}
          />
        </Modal>
      )}
    </>
  );
};
