import { useEffect, useRef } from "react";
import { useLogs } from "../../providers/LogProvider";

import { useInterval } from "usehooks-ts";
import dayjs from "dayjs";
import { Badge, Button } from "antd";
import { StyledLiveTail } from "./styled";

export const LiveTail = () => {
  const { logs, filters, setFilters, liveTail, setLiveTail, setFromDate } =
    useLogs();
  const liveTailRef = useRef(liveTail);

  useEffect(() => {
    liveTailRef.current = liveTail;
  }, [liveTail]);

  useInterval(
    () => {
      const dateFrom =
        logs.length > 0
          ? dayjs(logs[logs.length - 1]?.date).valueOf()
          : liveTail;
      if (filters !== null) {
        setFilters({
          ...filters,
          dateFrom: dateFrom.toString(),
          dateTo: dayjs().valueOf().toString(),
        });
      }
    },
    liveTail ? 3000 : null,
  );

  const handleLiveTail = () => {
    if (liveTailRef.current) {
      // turn off livetail
      setFromDate(liveTailRef.current.toString());
      setLiveTail(0);
    } else {
      if (filters !== null) {
        const liveTailVal = dayjs().subtract(10, "s").valueOf();
        setLiveTail(liveTailVal);
      }
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden" && liveTailRef.current !== 0) {
        timeoutId = setTimeout(() => {
          handleLiveTail();
        }, 300000); // 5 minutes timeout
      } else {
        clearTimeout(timeoutId);
      }
    };

    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (filters && liveTail !== 0 && filters.relativeTime) {
      console.log("live tail turned on");
      const activeFilters = { ...filters };
      delete activeFilters.relativeTime;
      setFilters({
        ...activeFilters,
        dateFrom: liveTail.toString(),
        dateTo: dayjs().valueOf().toString(),
      });
    }
  }, [liveTail]);

  return (
    <StyledLiveTail>
      <Button
        type="text"
        onClick={handleLiveTail}
        style={{ cursor: "pointer" }}
      >
        <Badge
          style={{ marginRight: "7px" }}
          color={liveTail ? "green" : "red"}
        />{" "}
        LIVE TAIL
      </Button>
    </StyledLiveTail>
  );
};
