import { Button, Typography } from "antd";
import dayjs from "dayjs";
import { LogType } from "../../api/types";
import { useLogs } from "../../providers/LogProvider";
const { Paragraph } = Typography;
import ReactJson from "react-json-view";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { timeFormatMillSec } from "../../config/const";
import { useEffect, useState } from "react";

export const LogWorker = ({ row }: { row: LogType }) => {
  const { filters, setFilters } = useLogs();

  const handleWorkerClick = (worker: string, parent?: string | null) => {
    if (!filters) return;
    let workerFilters = filters.worker || [];
    if (parent && workerFilters.includes(parent)) {
      workerFilters = workerFilters.filter((w) => w !== parent);
    }
    if (!workerFilters.includes(worker)) {
      workerFilters.push(worker);
    }

    setFilters({ ...filters, worker: workerFilters });
  };

  return (
    <>
      {row.workerParent ? (
        <>
          <span
            className="link"
            onClick={() => handleWorkerClick(row.workerParent || "")}
          >
            {`${row.workerParent}`}
          </span>
          <span
            className="link"
            onClick={() => handleWorkerClick(row.worker, row.workerParent)}
          >{` (#${row.worker.split(".")[1]})`}</span>
        </>
      ) : (
        [
          <span
            onClick={() => handleWorkerClick(row.worker)}
            className="link"
          >{`${row.worker}`}</span>,
        ]
      )}
    </>
  );
};

export const LogContent = ({
  text,
  id,
  metadata,
}: {
  text: string;
  id: string;
  metadata?: string;
}) => {
  const [isValidJson, setIsValidJson] = useState(false);
  const [parsedJson, setParsedJson] = useState(null);
  const { openedLogs } = useLogs();

  useEffect(() => {
    try {
      if (metadata) {
        let jsonData = JSON.parse(metadata);
        if (typeof jsonData === "string") {
          jsonData = JSON.parse(jsonData);
        }
        if (typeof jsonData === "object") {
          setIsValidJson(true);
        }
        setParsedJson(jsonData);
      }
    } catch (error) {
      console.log("Error parsing metadata", error);
    }
  }, [metadata]);

  const ellipsis = !openedLogs.includes(id);

  if (ellipsis) {
    return (
      <Paragraph
        style={{
          margin: 0,
          cursor: "pointer",
          textWrap: "nowrap",
          fontFamily: "Inconsolata, monospace",
          width: "100%",
        }}
        ellipsis
      >
        {text}{" "}
        {metadata && metadata !== "[]" && metadata !== "{}" && (
          <span>{metadata}</span>
        )}
      </Paragraph>
    );
  }

  return (
    <>
      <Paragraph
        style={{
          margin: 0,
          textWrap: "wrap",
          fontFamily: "Inconsolata, monospace",
          width: "100%",
        }}
        // onClick={handleClick}
      >
        {text}
        {/* TODO: disabled formatting for now */}
        {/* {jsonStrings.map((str) => {
          if (str.type === "json") {
            return (
              <div style={{ width: "100%" }}>
                <ReactJson
                  key={str.content}
                  src={JSON.parse(str.content)}
                  theme="solarized"
                  displayDataTypes={false}
                  collapseStringsAfterLength={40}
                  name={null}
                  displayObjectSize={false}
                />
              </div>
              // <pre>{str.content}</pre>
            );
          }
          return <span key={str.content}>{str.content}</span>;
        })} */}
      </Paragraph>{" "}
      {metadata && metadata !== "[]" && metadata !== "{}" && (
        <>
          {isValidJson && parsedJson ? (
            <ReactJson
              style={{ background: "transparent" }}
              key={metadata}
              src={parsedJson}
              theme="threezerotwofour"
              displayDataTypes={false}
              collapseStringsAfterLength={40}
              name={null}
              displayObjectSize={false}
            />
          ) : (
            <pre>{metadata}</pre>
          )}
        </>
      )}
    </>
  );
};

export const LogDate = ({
  id,
  date,
  log,
}: {
  id: string;
  date: string;
  log: LogType;
}) => {
  const { setFilters, filters, setHighlightedLog, setRequestTime } = useLogs();

  const handleDateClick = () => {
    if (!filters) return;
    setRequestTime(Date.now());
    const activeFilters = { ...filters };
    delete activeFilters["text"];
    delete activeFilters["relativeTime"];
    const timestamp = new Date(date).getTime();
    setHighlightedLog(id);
    setFilters({
      ...activeFilters,
      timestamp: "yes",
      dateFrom: timestamp.toString(),
      dateTo: timestamp.toString(),
      direction: "middle",
      worker: [log.worker],
    });
  };

  return (
    // <a
    //   href="/stats"
    //   style={{
    //     color:
    //       filters.timestamp === "yes" &&
    //       filters.dateFrom === new Date(date).getTime().toString() &&
    //       filters.dateTo === new Date(date).getTime().toString()
    //         ? "yellow"
    //         : "white",
    //   }}
    //   className="link"
    //   onClick={handleDateClick}
    // >
    //   {dayjs(date).format("YYYY-MM-DD HH:mm:ss.SSS")}
    // </a>
    <span className="link" onClick={handleDateClick}>
      {dayjs(date).format(timeFormatMillSec)}
    </span>
  );
};

export const LogExpand = ({ id }: { id: string }) => {
  const { openedLogs, setOpenedLogs } = useLogs();

  const handleClick = () => {
    if (openedLogs.includes(id)) {
      setOpenedLogs(openedLogs.filter((logId) => logId !== id));
    } else {
      setOpenedLogs([...openedLogs, id]);
    }
  };

  return (
    <Button
      type="link"
      onClick={handleClick}
      style={{
        height: "20px",
        padding: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      {openedLogs.includes(id) ? <CaretDownOutlined /> : <CaretRightOutlined />}
    </Button>
  );
};
